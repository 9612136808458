<template>
  <div class="blue menu pt-4 view-without-scroll">
    <v-list
      flat
      color="transparent"
      class="pt-4"
      v-if="role == 'admin' || role == 'operations_officer' || role == 'support'"
    >
      <v-list-item-group v-if="menuList(topList).length">
        <v-list-item
          v-for="item in menuList(topList)"
          :key="item.title"
          link
          :to="`/${item.link}`"
          class="font-weight-medium ms-4 me-6 my-1"
          active-class="menu-item--active"
        >
          <v-list-item-icon class="my-3 me-3">
            <v-img
              height="22"
              max-width="22"
              :src="getImage(item.icon)"
              class="icon"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="py-2">
            <v-list-item-title class="white--text menu__item-title f-16">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group v-if="menuList(request).length" class="pt-4">
        <div class="items-group__line" />
        <h3
            class="arabic-bold f-14 text-uppercase pt-6 ps-6 items-group__title pb-1 opacity-80"
        >
          {{ $t("Requests") }}
        </h3>
        <v-list-item
            v-for="item in menuList(request)"
            :key="item.title"
            style="height: 40px; min-height: 40px"
            link
            :to="`/${item.link}`"
            class="font-weight-medium ms-4 me-6 my-1"
            active-class="menu-item--active"
        >
          <v-list-item-content class="py-2">
            <v-list-item-title class="white--text menu__item-title ps-6">
              <span class="f-14"> {{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group v-if="menuList(estateList).length" class="pt-4">
        <div class="items-group__line" />
        <h3
          class="arabic-bold f-14 text-uppercase pt-6 ps-6 items-group__title pb-1 opacity-80"
        >
          {{ $t("realEstate") }}
        </h3>
        <v-list-item
          v-for="item in menuList(estateList)"
          :key="item.title"
          style="height: 40px; min-height: 40px"
          link
          :to="`/${item.link}`"
          class="font-weight-medium ms-4 me-6 my-1"
          active-class="menu-item--active"
        >
          <v-list-item-content class="py-2">
            <v-list-item-title class="white--text menu__item-title ps-6">
              <span class="f-14"> {{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group class="pt-4" v-if="menuList(finList).length">
        <div class="items-group__line" />
        <h3
          class="arabic-bold f-14 text-uppercase pt-6 ps-6 items-group__title pb-1 opacity-80"
        >
          {{ $t("financing") }}
        </h3>
        <v-list-item
          v-for="item in menuList(finList)"
          :key="item.title"
          style="height: 40px; min-height: 40px"
          link
          :to="`/${item.link}`"
          class="font-weight-medium ms-4 me-6 my-1"
          active-class="menu-item--active"
        >
          <v-list-item-content class="py-2">
            <v-list-item-title class="white--text menu__item-title ps-6">
              <span class="f-14">{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group class="py-4" v-if="menuList(other).length">
        <div class="items-group__line mb-4" />
        <v-list-item
          v-for="item in menuList(other)"
          :key="item.title"
          link
          :to="`/${item.link}`"
          class="font-weight-medium ms-4 me-6 my-1"
          active-class="menu-item--active"
        >
          <v-list-item-icon class="my-3 me-3">
            <v-img
              height="24"
              max-width="24"
              :src="getImage(item.icon)"
              class="icon"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="py-2">
            <v-list-item-title class="white--text menu__item-title">
              <span class="f-16">{{item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list flat color="transparent" class="pt-4" v-else>
      <v-list-item-group>
        <v-list-item
          v-for="item in menuList(items)"
          :key="item.title"
          link
          :to="`/${item.link}`"
          class="font-weight-medium ms-4 me-6 my-1"
          active-class="menu-item--active"
          v-show="!(item.link == 'loan-offers' && type == 'bidaya' && role == 'bank_manager')"
        >
          <v-list-item-icon class="my-3 me-3">
            <v-img
              height="20"
              max-width="20"
              :src="getImage(item.icon)"
              class="icon"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="py-2">
            <v-list-item-title class="white--text menu__item-title">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      request: [
        {
          title: this.$t("Financial"),
          link: "requests",
          icon: "requests",
          disabled: ["bank_manager", "bank_agent", "support"],
        },
        {
          title: this.$t("Real estate"),
          link: "real-estate-requests",
          disabled: ["bank_manager", "bank_agent", "support"],
        },
      ],

      topList: [
        {
          title: this.$t("Dashboard"),
          link: "dashboard",
          icon: "dashboard",
          disabled: [],
        },
        {
          title: this.$tc("Customer", 2),
          link: "customers",
          icon: "staff",
          disabled: ["bank_agent", "bank_agent", "support"],
        },
        {
          title: this.$t("Staff"),
          link: "staff",
          icon: "staff",
          disabled: ["bank_manager", "bank_agent", "support"],
        },
      ],
      estateList: [
        {
          title: this.$t("agents"),
          link: "agents",
          disabled: ["bank_manager", "bank_agent", "support"],
        },
        {
          title: this.$t("Agencies"),
          link: "agencies",
          disabled: ["bank_manager", "bank_agent"],
        },
        {
          title: this.$tc("projects.title", 2),
          link: "projects",
          disabled: ["bank_manager", "bank_agent", "support"],
        },
        {
          title: this.$tc("property.title", 2),
          link: "properties",
          disabled: ["bank_manager", "bank_agent"],
        },
      ],
      finList: [
        {
          title: this.$tc("Organization", 2),
          link: "organizations",
          disabled: ["support"],
        },
        {
          title: this.$tc("apr.title", 1),
          link: "apr-settings",
          disabled: ["support", "bank_agent", "bank_manager"],
        },
        // {
        //   title: this.$t("Products"),
        //   link: "products",
        //   disabled: ["bank_agent", "support"],
        // },
      ],
      other: [
        {
          title: this.$tc("ticket.title", 1),
          link: "tickets",
          icon: "ticket",
          disabled: ["bank_manager", "bank_agent"],
        },
        {
          title: this.$t("settings.title"),
          link: "settings",
          icon: "settings",
          disabled: ["bank_manager", "bank_agent", "support", "bank_sales_agent", "operations_officer"],
        },

      ],
      items: [
        {
          title: this.$t("Dashboard"),
          link: "dashboard",
          icon: "dashboard",
          disabled: [],
        },
        {
          title: this.$t("Requests"),
          link: "requests",
          icon: "requests",
          disabled: ["support"],
        },
        {
          title: this.$t("loanOffers"),
          link: "loan-offers",
          icon: "products",
          disabled: ["support", "bank_agent", "bank_sales_agent"],
        },
        // {
        //   title: this.$t("Products"),
        //   link: "products",
        //   icon: "products-full",
        //   disabled: ["bank_agent", "support"],
        // },
        {
          title: this.$tc("Organization", 1),
          link: "organization-details",
          icon: "organization-fill",
          disabled: ["admin", "operations_officer", "support"],
        },
      ]
    };
  },
  mounted() {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    this.$i18n.locale = lang;
    this.$vuetify.locale = lang;
    this.$vuetify.rtl = lang == 'ar';
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icons/${icon}.svg`);
    },
    menuList(list) {
      return list.filter((item) => !item.disabled.includes(this.role));
    },
  },
  computed: {
    ...mapState({
      role: (state) => state.profile.profile.role,
      type: (state) => state.profile.profile?.organization?.type,
    }),
  },
};
</script>
<style lang="scss" scoped>
.menu {
  min-height: calc(100vh - 64px);
  width: 240px;
  min-width: 240px;

  & .v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    min-height: 44px;
    height: 44px;
    margin: 0 !important;
    padding-left: 24px !important;
  }

  &__item-title {
    font-size: 14px;
    z-index: 1;
  }
}

.menu-item--active::before {
  content: "";
  display: block !important;
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-left: 6px solid #7ab845;
  background-color: #014777 !important;
  opacity: 1 !important;
}

.items-group {
  &__title {
    color: rgba(255, 255, 255, 0.8);
  }

  &__line {
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
  }
}
</style>
