import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth.js";
import profile from "@/store/modules/profile.js";
import staff from "@/store/modules/staff.js";
import organization from "@/store/modules/organization.js";
import product from "@/store/modules/product.js";
import request from "@/store/modules/request";
import notification from "@/store/modules/notification";
import customer from "@/store/modules/customer";
import agency from "@/store/modules/agency";
import agent from "@/store/modules/agent";
import property from "@/store/modules/property";
import ticket from "@/store/modules/ticket";
import statistics from "@/store/modules/statistics";
import agenciesAgents from "@/store/modules/agenciesAgents";
import projects from "@/store/modules/projects";
import apr from "@/store/modules/apr";
import common from "@/store/modules/common";
import fab from "@/store/modules/fab";
import realEstate from "@/store/modules/realEstate"
import settings from "@/store/modules/settings"

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    profile,
    staff,
    organization,
    product,
    request,
    notification,
    customer,
    agency,
    agent,
    property,
    ticket,
    realEstate,
    settings,
    statistics,
    agenciesAgents,
    projects,
    apr,
    common,
    fab,
  },
});

export default store;
